// Data
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

// ENV
import { PUBLIC_SENTRY_DSN } from '$env/static/public'

Sentry.init({
	dsn: process.env.NODE_ENV === 'production' ? PUBLIC_SENTRY_DSN : '',
	environment: process.env.NODE_ENV,
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		replayIntegration(),
		Sentry.captureConsoleIntegration({
			levels: ['error']
		})
	]
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
